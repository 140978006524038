@import url(https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap);
body {
  font-family: "Sora", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sora", sans-serif;
  font-weight: 800;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  line-height: 1.75rem;
}

li {
  line-height: 1.5rem;
  margin-bottom: 10px;
}

.header-logo {
  height: 60px; /* Adjust based on your design */
}

.header-title {
  font-size: 36px;
  color: inherit; /* Inherits white or black from header style */
  margin: 0 auto; /* Centers title if there is room */
}

.social-icons {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.social-icons img {
  width: 40px;
  height: 40px;
  z-index: 0;
}

.container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  overflow: hidden;
}

.events-list {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event {
  margin-bottom: 10px;
  border: none;
  background-color: white;
  width: 800px;
  border-radius: 20px;
  padding: 10px;
}

.eventTitle,
.Calendar {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.calendar-icon,
.time-icon {
  margin-right: 10px;
}
.call-icon-home {
  background-color: black;
  color: white;
  padding: 5px 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-icon-home img {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.call-icon-home span {
  font-size: 20px;
  margin-right: 20px;
}

a {
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .secondary-header-logo {
    height: 45px;
  }
  .call-icon-home {
    padding: 5px;
    width: 30px;
    height: 30px;
    border: none;
  }
  .call-icon-home span {
    display: none;
  }
  .social-icons {
    grid-gap: 10px;
    gap: 10px;
  }
}

/* Styling for the main section container */
.main-section {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  justify-content: center;
  position: relative;
  margin: 0 auto 30px auto;
  max-width: 1350px;
}

.image-box-link {
  text-decoration: none;
}

.image-box {
  width: calc(33.333% - 20px);
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  height: 270px;
  border-radius: 20px;
  filter: brightness(2);
}

.image-box-content {
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 30px;
  border-radius: 20px;
  height: 270px;
}

.image-box-content h2 {
  font-size: 2.6rem;
  text-shadow: 5px 5px 10px #0000006e;
  margin: 0;
  font-weight: 600;
}

.image-box-icon {
  position: absolute;
  bottom: 20px;
  right: 30px;
  color: white;
  text-decoration: none;
}

.cta {
  display: flex;
  justify-content: center;
  background-color: #ded2be;
  background-image: url(/static/media/watermark-shiraz.84081769.png);
  border-radius: 20px;
  max-width: 1310px;
  margin: 0 auto;
  padding: 20px;
  font-size: 30px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .main-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image-box {
    width: calc(100% - 30px);
    height: 220px;
  }

  header {
    padding: 0 15px;
  }

  .header-logo {
    height: 45px;
  }

  .call-icon span {
    display: none;
  }
  .call-icon {
    padding: 10px;
  }
  .cta {
    margin: 0 20px 30px 20px;
  }
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 20px;
  max-width: 1310px !important;
  margin: 0 auto;
  border-radius: 20px;
  margin-bottom: 30px;
}

.contact-address,
.contact-details,
.contact-map {
  flex-basis: calc(33.333% - 40px);
  text-align: left;
}
.contact-details {
  margin-top: 54px;
}
.contact-map {
  text-align: right;
}

.footer-map {
  width: 100%;
  max-height: 250px;
  object-fit: contain;
  border-radius: 20px;
}

a {
  color: #fff;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-address,
  .contact-details,
  .contact-map {
    flex-basis: 100%; /* Takes up full width */
    text-align: left; /* Align text to the left on smaller screens */
    margin-bottom: 20px; /* Adds space between the items */
  }

  .contact-map {
    order: -1; /* Moves the map to the top on smaller screens */
  }
  .footer-container {
    margin: 0 20px 30px 20px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.secondary-header-logo {
  height: 60px;
  z-index: 0;
  filter: brightness(1.5);
}
.secondheader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inside-style {
  display: flex;
  align-items: center;
  max-width: 1340px;
  margin: 0 auto;
  justify-content: space-between;
  color: white;
}

.secondary-header-title {
  font-size: 36px;
  color: inherit; /* Inherits white or black from header style */
  margin: 0 auto; /* Centers title if there is room */
}

.social-icons-home {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.secondheader {
  z-index: 0;
}

.social-icons-home img {
  width: 40px;
  height: 40px;
  z-index: 0;
}

.call-icon {
  background-color: black;
  color: white;
  padding: 5px 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-icon img {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.call-icon span {
  font-size: 20px;
  margin-right: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .secondary-header-logo {
    height: 45px;
  }
  .call-icon {
    padding: 3px;
    width: 30px;
    height: 30px;
    border: 3px solid white;
    background: none;
  }
  .social-icons-home {
    grid-gap: 10px;
    gap: 10px;
  }
  img.instagram-icon,
  img.whatsapp-icon {
    width: 35px;
    height: 35px;
  }
}

header {
  z-index: 2;
  position: relative;
}

.container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -70px;
  z-index: 1;
}

.SecondaryHeader {
  position: relative;
  z-index: 0;
}

.hero-section {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 500px;
  width: 100%;
  margin-top: -110px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #000;
  background-size: cover;
  background-image: url(/static/media/celebrate-banner.d35da777.png);
}

.hero-breadcrumb a {
  color: #fff;
  text-decoration: underline;
}

.hero-content {
  display: flex;
  flex-direction: column;
  width: 55vw;
  color: #fff;
  margin-top: 50px;
  text-shadow: 6px 6px 10px #00000059;
}

.hero-title {
  font-size: 2rem;
  line-height: 1;
}

.hero-title h1 {
  margin: 20px 0;
}

.hero-button {
  border: 1px solid #fff;
  border-radius: 25px;
  padding: 10px 12px;
  font-size: 1.5rem;
  width: 150px;
}

.hero-button a {
  color: #fff;
  text-decoration: none;
}

.calendar {
  display: flex;
}

.calendar-icon {
  padding-right: 7px;
}

.time-icon {
  padding-right: 7px;
  padding-left: 20px;
}

.content {
  width: 80%; /* Adjust width as needed */
  max-width: 800px;
  margin: 20px auto; /* Centers the div horizontally, adds vertical spacing */
  border-radius: 20px;
  padding: 20px; /* Adds padding inside the content box */
  background-color: white;
}

.image-box-icon img {
  width: 50px;
}

.event {
  padding: 20px; /* Ensure padding for content within events */
  width: 100%; /* Ensures consistent width with content area */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(228, 223, 223);
  position: relative;
}

.events-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55vw;
  border-radius: 20px;
  padding: 30px;
  grid-gap: 10px;
  gap: 10px;
}

.event-title {
  font-size: 2rem;
  line-height: 0;
  margin: 10px 0 32px 0;
}

.event-item {
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 20px #0000001f;
  margin-bottom: 10px;
  padding: 20px;
}

.image-slider {
  display: flex;
  overflow: hidden;
  grid-gap: 30px;
  gap: 30px;
  width: auto;
}

.slide-image {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  animation: slide 8s linear infinite;
}

.ot-container__iframe {
  border-radius: 20px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 30px));
  }
}

/* Add styling to remove scrollbar visibility but keep functionality */
.image-slider::-webkit-scrollbar {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    width: 90vw;
  }

  .events-list {
    width: 100% !important;
  }

  .content {
    width: 90%;
    padding: 15px;
  }

  .event-title {
    font-size: 24px;
  }
  .hero-content {
    width: 100vw;
    margin: 50px 20px 0 20px;
  }
  .hero-title {
    font-size: 1.5rem;
  }
  .hero-section {
    background-repeat: no-repeat;
    background-position: right;
  }
  header {
    padding: 0;
  }
}

/* Funeral.css */

.funeral-hero-section {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 500px;
  width: 100%;
  margin-top: -110px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #000;
  background-size: cover;
  background-image: url(/static/media/funeral-banner.8ffa4e93.png);
}

.funeral-hero-breadcrumb a {
  color: #fff;
  text-decoration: underline;
}

.funeral-hero-content {
  display: flex;
  flex-direction: column;
  width: 55vw;
  color: #fff;
  margin-top: 50px;
  text-shadow: 6px 6px 10px #00000059;
}

.funeral-hero-title {
  font-size: 2rem;
  line-height: 1;
}

.funeral-hero-title h1 {
  margin: 20px 0;
}

.funeral-hero-button {
  border: 1px solid #fff;
  border-radius: 25px;
  padding: 10px 12px;
  font-size: 1.5rem;
  width: 150px;
}

.funeral-hero-button a {
  color: #fff;
  text-decoration: none;
}

.funeral-container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -70px;
  z-index: 1;
}
.funeral-page {
  position: relative;
  z-index: 0;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}

.funeral-page .funeral-hero-text {
  color: black;
  padding: 5px 50px;
  justify-content: center;
  width: 55vw;
  background-color: #fff;
  border-radius: 25px 25px 0 0;
}

.funeral-anfragen-section {
  width: 55vw;
}

.funeral-hero-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
}
.funeral-hero-text h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.funeral-page .content {
  text-align: left; /* Align text to the left */
}

.image-slider {
  display: flex;
  overflow: hidden;
  grid-gap: 30px;
  gap: 30px;
  width: auto;
  margin: 30px 0;
}

.slide-image {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  animation: slide 8s linear infinite;
}

.styled-iframe {
  width: 100%;
  height: 693px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding-top: 30px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 30px));
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    width: 90vw;
  }

  .cog-cognito {
    width: 80%;
  }
  .styled-iframe {
    height: 700px !important;
  }

  .content {
    width: 90%;
    padding: 15px;
  }

  .funeral-hero-text h2 {
    font-size: 1.8rem;
  }

  .funeral-hero-content {
    width: 100vw !important;
    margin: 50px 20px 0 20px !important;
  }
  .funeral-hero-title {
    font-size: 1.3rem !important;
  }
  .funeral-hero-section {
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 400px !important;
  }
  .funeral-page .funeral-hero-text {
    padding: 5px 25px !important;
    width: 80vw !important;
    margin-top: 30px !important;
  }
  .funeral-anfragen-section {
    width: 90vw !important;
  }
}

.hochzeit-hero-section {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 500px;
  width: 100%;
  margin-top: -110px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #000;
  background-size: cover;
  background-image: url(/static/media/hochzeit-banner.9ba5780a.png);
}

.hochzeit-hero-breadcrumb a {
  color: #fff;
  text-decoration: underline;
}

.hochzeit-hero-content {
  display: flex;
  flex-direction: column;
  width: 55vw;
  color: #fff;
  margin-top: 50px;
  text-shadow: 6px 6px 10px #00000059;
}

.hochzeit-hero-title {
  font-size: 2rem;
  line-height: 1;
}

.hochzeit-hero-title h1 {
  margin: 20px 0;
}

.hochzeit-hero-button {
  border: 1px solid #fff;
  border-radius: 25px;
  padding: 10px 12px;
  font-size: 1.5rem;
  width: 150px;
}

.hochzeit-hero-button a {
  color: #fff;
  text-decoration: none;
}

.hochzeit-container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -70px;
  z-index: 1;
}

.hochzeit-page {
  position: relative;
  z-index: 0;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}

.hochzeit-page .hochzeit-hero-section {
  height: 400px; /* or the height you desire */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px; /* Optional, if you want rounded corners */
}

.hochzeit-page .hochzeit-hero-text {
  color: black;
  padding: 5px 50px;
  justify-content: center;
  width: 55vw;
  background-color: #fff;
  border-radius: 25px 25px 0 0;
}

.hochzeit-anfragen-section {
  width: 55vw;
}

.hochzeit-hero-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
}
.hochzeit-hero-text h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.hochzeit-page .content {
  text-align: left; /* Align text to the left */
}

.image-slider {
  display: flex;
  overflow: hidden;
  grid-gap: 30px;
  gap: 30px;
  width: auto;
  margin: 30px 0;
}

.slide-image {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  animation: slide 8s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 30px));
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    width: 90vw;
  }

  .styled-iframe {
    height: 700px !important;
    padding: 15px 25px !important;
    width: 85% !important;
  }
  html#cognito {
    overflow: hidden !important;
  }

  .content {
    width: 90%;
    padding: 15px;
  }

  .hochzeit-hero-text h2 {
    font-size: 1.8rem;
  }

  .hochzeit-hero-content {
    width: 100vw !important;
    margin: 50px 20px 0 20px !important;
  }
  .hochzeit-hero-title {
    font-size: 1.3rem !important;
  }
  .hochzeit-hero-section {
    background-repeat: no-repeat !important;
    background-position: top !important;
    height: 400px !important;
  }
  .hochzeit-page .hochzeit-hero-text {
    padding: 5px 25px !important;
    width: 80vw !important;
    margin-top: 30px !important;
  }
  .hochzeit-anfragen-section {
    width: 90vw !important;
  }
}

/* Funeral.css */

.workshop-hero-section {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 500px;
  width: 100%;
  margin-top: -110px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #000;
  background-size: cover;
  background-image: url(/static/media/workshop-banner.171237ad.png);
}

.workshop-hero-breadcrumb a {
  color: #fff;
  text-decoration: underline;
}

.events-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55vw;
  border-radius: 20px;
  padding: 20px 0;
  grid-gap: 10px;
  gap: 10px;
}

.workshop-hero-content {
  display: flex;
  flex-direction: column;
  width: 55vw;
  color: #fff;
  margin-top: 50px;
  text-shadow: 6px 6px 10px #00000059;
}

.workshop-hero-title {
  font-size: 2rem;
  line-height: 1;
}

.workshop-hero-title h1 {
  margin: 20px 0;
}

.workshop-hero-button {
  border: 1px solid #fff;
  border-radius: 25px;
  padding: 10px 12px;
  font-size: 1.5rem;
  width: 150px;
}

.workshop-hero-button a {
  color: #fff;
  text-decoration: none;
}

.workshop-container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -70px;
  z-index: 1;
}
.workshop-page {
  position: relative;
  z-index: 0;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}

.workshop-page .workshop-hero-text {
  color: black;
  padding: 5px 50px;
  justify-content: center;
  width: 55vw;
  background-color: #fff;
  border-radius: 25px 25px 0 0;
}

.workshop-anfragen-section {
  width: 55vw;
}

.workshop-hero-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
}
.workshop-hero-text h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.workshop-page .content {
  text-align: left; /* Align text to the left */
}

.image-slider {
  display: flex;
  overflow: hidden;
  grid-gap: 30px;
  gap: 30px;
  width: auto;
  margin: 30px 0;
}

.slide-image {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  animation: slide 8s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 30px));
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    width: 90vw;
  }

  .content {
    width: 90%;
    padding: 15px;
  }

  .workshop-hero-text h2 {
    font-size: 1.8rem;
  }

  .workshop-hero-content {
    width: 100vw !important;
    margin: 50px 20px 0 20px !important;
  }
  .workshop-hero-title {
    font-size: 1.3rem !important;
  }
  .workshop-hero-section {
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 400px !important;
  }
  .workshop-page .workshop-hero-text {
    padding: 5px 25px !important;
    width: 80vw !important;
    margin-top: 30px !important;
  }
  .workshop-anfragen-section {
    width: 90vw !important;
  }
}

/* Funeral.css */

.tagung-hero-section {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 500px;
  width: 100%;
  margin-top: -110px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #000;
  background-size: cover;
  background-image: url(/static/media/tagung-banner.2bb53862.png);
}

.tagung-hero-breadcrumb a {
  color: #fff;
  text-decoration: underline;
}

.tagung-hero-content {
  display: flex;
  flex-direction: column;
  width: 55vw;
  color: #fff;
  margin-top: 50px;
  text-shadow: 6px 6px 10px #00000059;
}

.tagung-hero-title {
  font-size: 2rem;
  line-height: 1;
}

.tagung-hero-title h1 {
  margin: 20px 0;
}

.tagung-hero-button {
  border: 1px solid #fff;
  border-radius: 25px;
  padding: 10px 12px;
  font-size: 1.5rem;
  width: 150px;
}

.tagung-hero-button a {
  color: #fff;
  text-decoration: none;
}

.tagung-container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -70px;
  z-index: 1;
}
.tagung-page {
  position: relative;
  z-index: 0;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}

.tagung-page .tagung-hero-text {
  color: black;
  padding: 5px 50px;
  justify-content: center;
  width: 55vw;
  background-color: #fff;
  border-radius: 25px 25px 0 0;
}

.tagung-anfragen-section {
  width: 55vw;
}

.tagung-hero-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
}
.tagung-hero-text h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.tagung-page .content {
  text-align: left; /* Align text to the left */
}

.image-slider {
  display: flex;
  overflow: hidden;
  grid-gap: 30px;
  gap: 30px;
  width: auto;
  margin: 30px 0;
}

.slide-image {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  animation: slide 8s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 30px));
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    width: 90vw;
  }

  .content {
    width: 90%;
    padding: 15px;
  }

  .cog-cognito {
    width: 80% !important;
  }
  .styled-iframe {
    height: 1000px !important;
  }

  .tagung-hero-text h2 {
    font-size: 1.8rem;
  }

  .tagung-hero-content {
    width: 100vw !important;
    margin: 50px 20px 0 20px !important;
  }
  .tagung-hero-title {
    font-size: 1.3rem !important;
  }
  .tagung-hero-section {
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 400px !important;
  }
  .tagung-page .tagung-hero-text {
    padding: 5px 25px !important;
    width: 80vw !important;
    margin-top: 30px !important;
  }
  .tagung-anfragen-section {
    width: 90vw !important;
  }
}

/* Funeral.css */

.sonstiges-hero-section {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 500px;
  width: 100%;
  margin-top: -110px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #000;
  background-size: cover;
  background-image: url(/static/media/sonstiges-banner.4e2280e6.png);
}

.sonstiges-hero-breadcrumb a {
  color: #fff;
  text-decoration: underline;
}

.sonstiges-hero-content {
  display: flex;
  flex-direction: column;
  width: 55vw;
  color: #fff;
  margin-top: 50px;
  text-shadow: 6px 6px 10px #00000059;
}

.sonstiges-hero-title {
  font-size: 2rem;
  line-height: 1;
}

.sonstiges-hero-title h1 {
  margin: 20px 0;
}

.sonstiges-hero-button {
  border: 1px solid #fff;
  border-radius: 25px;
  padding: 10px 12px;
  font-size: 1.5rem;
  width: 150px;
}

.sonstiges-hero-button a {
  color: #fff;
  text-decoration: none;
}

.sonstiges-container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -70px;
  z-index: 1;
}
.sonstiges-page {
  position: relative;
  z-index: 0;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}

.sonstiges-page .sonstiges-hero-text {
  color: black;
  padding: 5px 50px;
  justify-content: center;
  width: 55vw;
  background-color: #fff;
  border-radius: 25px 25px 0 0;
}

.sonstiges-anfragen-section {
  width: 55vw;
}

.sonstiges-hero-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
}
.sonstiges-hero-text h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.sonstiges-page .content {
  text-align: left; /* Align text to the left */
}

.image-slider {
  display: flex;
  overflow: hidden;
  grid-gap: 30px;
  gap: 30px;
  width: auto;
  margin: 30px 0;
}

.slide-image {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  animation: slide 8s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 30px));
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    width: 90vw;
  }

  .content {
    width: 90%;
    padding: 15px;
  }

  .cog-cognito {
    width: 80%;
  }
  .styled-iframe {
    height: 1000px !important;
  }

  .sonstiges-hero-text h2 {
    font-size: 1.8rem;
  }

  .sonstiges-hero-content {
    width: 100vw !important;
    margin: 50px 20px 0 20px !important;
  }
  .sonstiges-hero-title {
    font-size: 1.3rem !important;
  }
  .sonstiges-hero-section {
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 400px !important;
  }
  .sonstiges-page .sonstiges-hero-text {
    padding: 5px 25px !important;
    width: 80vw !important;
    margin-top: 30px !important;
  }
  .sonstiges-anfragen-section {
    width: 90vw !important;
  }
}

