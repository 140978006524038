header {
  z-index: 2;
  position: relative;
}

.container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -70px;
  z-index: 1;
}

.SecondaryHeader {
  position: relative;
  z-index: 0;
}

.hero-section {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 500px;
  width: 100%;
  margin-top: -110px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #000;
  background-size: cover;
  background-image: url("../../../images/celebrate-banner.png");
}

.hero-breadcrumb a {
  color: #fff;
  text-decoration: underline;
}

.hero-content {
  display: flex;
  flex-direction: column;
  width: 55vw;
  color: #fff;
  margin-top: 50px;
  text-shadow: 6px 6px 10px #00000059;
}

.hero-title {
  font-size: 2rem;
  line-height: 1;
}

.hero-title h1 {
  margin: 20px 0;
}

.hero-button {
  border: 1px solid #fff;
  border-radius: 25px;
  padding: 10px 12px;
  font-size: 1.5rem;
  width: 150px;
}

.hero-button a {
  color: #fff;
  text-decoration: none;
}

.calendar {
  display: flex;
}

.calendar-icon {
  padding-right: 7px;
}

.time-icon {
  padding-right: 7px;
  padding-left: 20px;
}

.content {
  width: 80%; /* Adjust width as needed */
  max-width: 800px;
  margin: 20px auto; /* Centers the div horizontally, adds vertical spacing */
  border-radius: 20px;
  padding: 20px; /* Adds padding inside the content box */
  background-color: white;
}

.image-box-icon img {
  width: 50px;
}

.event {
  padding: 20px; /* Ensure padding for content within events */
  width: 100%; /* Ensures consistent width with content area */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(228, 223, 223);
  position: relative;
}

.events-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55vw;
  border-radius: 20px;
  padding: 30px;
  gap: 10px;
}

.event-title {
  font-size: 2rem;
  line-height: 0;
  margin: 10px 0 32px 0;
}

.event-item {
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 20px #0000001f;
  margin-bottom: 10px;
  padding: 20px;
}

.image-slider {
  display: flex;
  overflow: hidden;
  gap: 30px;
  width: auto;
}

.slide-image {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  animation: slide 8s linear infinite;
}

.ot-container__iframe {
  border-radius: 20px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 30px));
  }
}

/* Add styling to remove scrollbar visibility but keep functionality */
.image-slider::-webkit-scrollbar {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    width: 90vw;
  }

  .events-list {
    width: 100% !important;
  }

  .content {
    width: 90%;
    padding: 15px;
  }

  .event-title {
    font-size: 24px;
  }
  .hero-content {
    width: 100vw;
    margin: 50px 20px 0 20px;
  }
  .hero-title {
    font-size: 1.5rem;
  }
  .hero-section {
    background-repeat: no-repeat;
    background-position: right;
  }
  header {
    padding: 0;
  }
}
