.header-logo {
  height: 60px; /* Adjust based on your design */
}

.header-title {
  font-size: 36px;
  color: inherit; /* Inherits white or black from header style */
  margin: 0 auto; /* Centers title if there is room */
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icons img {
  width: 40px;
  height: 40px;
  z-index: 0;
}

.container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  overflow: hidden;
}

.events-list {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event {
  margin-bottom: 10px;
  border: none;
  background-color: white;
  width: 800px;
  border-radius: 20px;
  padding: 10px;
}

.eventTitle,
.Calendar {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.calendar-icon,
.time-icon {
  margin-right: 10px;
}
.call-icon-home {
  background-color: black;
  color: white;
  padding: 5px 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-icon-home img {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.call-icon-home span {
  font-size: 20px;
  margin-right: 20px;
}

a {
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .secondary-header-logo {
    height: 45px;
  }
  .call-icon-home {
    padding: 5px;
    width: 30px;
    height: 30px;
    border: none;
  }
  .call-icon-home span {
    display: none;
  }
  .social-icons {
    gap: 10px;
  }
}
