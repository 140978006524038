/* Styling for the main section container */
.main-section {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  position: relative;
  margin: 0 auto 30px auto;
  max-width: 1350px;
}

.image-box-link {
  text-decoration: none;
}

.image-box {
  width: calc(33.333% - 20px);
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  height: 270px;
  border-radius: 20px;
  filter: brightness(2);
}

.image-box-content {
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 30px;
  border-radius: 20px;
  height: 270px;
}

.image-box-content h2 {
  font-size: 2.6rem;
  text-shadow: 5px 5px 10px #0000006e;
  margin: 0;
  font-weight: 600;
}

.image-box-icon {
  position: absolute;
  bottom: 20px;
  right: 30px;
  color: white;
  text-decoration: none;
}

.cta {
  display: flex;
  justify-content: center;
  background-color: #ded2be;
  background-image: url("../images/watermark-shiraz.png");
  border-radius: 20px;
  max-width: 1310px;
  margin: 0 auto;
  padding: 20px;
  font-size: 30px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .main-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image-box {
    width: calc(100% - 30px);
    height: 220px;
  }

  header {
    padding: 0 15px;
  }

  .header-logo {
    height: 45px;
  }

  .call-icon span {
    display: none;
  }
  .call-icon {
    padding: 10px;
  }
  .cta {
    margin: 0 20px 30px 20px;
  }
}
