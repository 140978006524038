/* Funeral.css */

.sonstiges-hero-section {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 500px;
  width: 100%;
  margin-top: -110px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #000;
  background-size: cover;
  background-image: url("../../images/sonstiges-banner.png");
}

.sonstiges-hero-breadcrumb a {
  color: #fff;
  text-decoration: underline;
}

.sonstiges-hero-content {
  display: flex;
  flex-direction: column;
  width: 55vw;
  color: #fff;
  margin-top: 50px;
  text-shadow: 6px 6px 10px #00000059;
}

.sonstiges-hero-title {
  font-size: 2rem;
  line-height: 1;
}

.sonstiges-hero-title h1 {
  margin: 20px 0;
}

.sonstiges-hero-button {
  border: 1px solid #fff;
  border-radius: 25px;
  padding: 10px 12px;
  font-size: 1.5rem;
  width: 150px;
}

.sonstiges-hero-button a {
  color: #fff;
  text-decoration: none;
}

.sonstiges-container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -70px;
  z-index: 1;
}
.sonstiges-page {
  position: relative;
  z-index: 0;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}

.sonstiges-page .sonstiges-hero-text {
  color: black;
  padding: 5px 50px;
  justify-content: center;
  width: 55vw;
  background-color: #fff;
  border-radius: 25px 25px 0 0;
}

.sonstiges-anfragen-section {
  width: 55vw;
}

.sonstiges-hero-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
}
.sonstiges-hero-text h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.sonstiges-page .content {
  text-align: left; /* Align text to the left */
}

.image-slider {
  display: flex;
  overflow: hidden;
  gap: 30px;
  width: auto;
  margin: 30px 0;
}

.slide-image {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  animation: slide 8s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 30px));
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    width: 90vw;
  }

  .content {
    width: 90%;
    padding: 15px;
  }

  .cog-cognito {
    width: 80%;
  }
  .styled-iframe {
    height: 1000px !important;
  }

  .sonstiges-hero-text h2 {
    font-size: 1.8rem;
  }

  .sonstiges-hero-content {
    width: 100vw !important;
    margin: 50px 20px 0 20px !important;
  }
  .sonstiges-hero-title {
    font-size: 1.3rem !important;
  }
  .sonstiges-hero-section {
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 400px !important;
  }
  .sonstiges-page .sonstiges-hero-text {
    padding: 5px 25px !important;
    width: 80vw !important;
    margin-top: 30px !important;
  }
  .sonstiges-anfragen-section {
    width: 90vw !important;
  }
}
