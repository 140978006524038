@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");

body {
  font-family: "Sora", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sora", sans-serif;
  font-weight: 800;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  line-height: 1.75rem;
}

li {
  line-height: 1.5rem;
  margin-bottom: 10px;
}
