.secondary-header-logo {
  height: 60px;
  z-index: 0;
  filter: brightness(1.5);
}
.secondheader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inside-style {
  display: flex;
  align-items: center;
  max-width: 1340px;
  margin: 0 auto;
  justify-content: space-between;
  color: white;
}

.secondary-header-title {
  font-size: 36px;
  color: inherit; /* Inherits white or black from header style */
  margin: 0 auto; /* Centers title if there is room */
}

.social-icons-home {
  display: flex;
  gap: 20px;
}
.secondheader {
  z-index: 0;
}

.social-icons-home img {
  width: 40px;
  height: 40px;
  z-index: 0;
}

.call-icon {
  background-color: black;
  color: white;
  padding: 5px 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-icon img {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.call-icon span {
  font-size: 20px;
  margin-right: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .secondary-header-logo {
    height: 45px;
  }
  .call-icon {
    padding: 3px;
    width: 30px;
    height: 30px;
    border: 3px solid white;
    background: none;
  }
  .social-icons-home {
    gap: 10px;
  }
  img.instagram-icon,
  img.whatsapp-icon {
    width: 35px;
    height: 35px;
  }
}
