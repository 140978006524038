.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 20px;
  max-width: 1310px !important;
  margin: 0 auto;
  border-radius: 20px;
  margin-bottom: 30px;
}

.contact-address,
.contact-details,
.contact-map {
  flex-basis: calc(33.333% - 40px);
  text-align: left;
}
.contact-details {
  margin-top: 54px;
}
.contact-map {
  text-align: right;
}

.footer-map {
  width: 100%;
  max-height: 250px;
  object-fit: contain;
  border-radius: 20px;
}

a {
  color: #fff;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-address,
  .contact-details,
  .contact-map {
    flex-basis: 100%; /* Takes up full width */
    text-align: left; /* Align text to the left on smaller screens */
    margin-bottom: 20px; /* Adds space between the items */
  }

  .contact-map {
    order: -1; /* Moves the map to the top on smaller screens */
  }
  .footer-container {
    margin: 0 20px 30px 20px;
  }
}
